// this file contains the style for sidebar and the content in sidebar
/*****************************
	-- PRODUCT SIDEBAR
*****************************/
.sidebar-card {
  background: #fff;
  border-radius: 4px;
  border: 1px solid $border-color;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  .card-title {
    padding: 30px;
    border-bottom: 1px solid $border-color;
  }
}

.product--sidebar {
  .sidebar-card {
    background: #fff;
    border-radius: 4px;
    margin-bottom: 30px;
    &:last-child{
      margin-bottom: 0;
    }
    .card-title {
      padding: 20px 25px;
      display: block;
      margin-bottom: 0;
      &.collapsed {
        margin-bottom: 0;
      }
      span {
        float: right;
        font-size: 12px;
        line-height: 20px;
        color: #555;
      }
      h5{
        display:flex;
        justify-content: space-between;
        align-items:center;
      }
    }
    .collapsible-content {
      padding: 0 25px;
    }
    .card-content {
      padding: 15px 0;
    }
  }
  .card--category {
    a {
      display: block;
    }
    ul {
      li {
        position: relative;
        padding-left: 15px;
        &:before {
          position: absolute;
          content: "";
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: $text-color2;
          left: 0;
          top: 15px;
        }
      }
      li a {
        &:hover {
          @extend .color-primary;
        }
        display: block;
        line-height: 36px;
        transition: $transition;
        color: $text-color2;
        span.fa {
          font-size: 6px;
          margin-right: 10px;
          position: relative;
          top: -3px;
        }
        .item-count {
          float: right;
        }
      }
    }
  }
  .card--slider {
    .card-content {
      padding: 30px 0;
      .price-ranges {
        padding-top: 25px;
        .from {
          margin-right: 10px;
        }
      }
    }
  }
}

/* SINGLE PRODUCT SIDEBAR */
.sidebar-card {
  &.card-pricing {
    padding: 30px;
    .price {
      padding: 30px 0;
      text-align: center;
      margin-bottom: 20px;
      border: 3px solid $border-color;
      border-radius: 3px;
      h1 {
        line-height: 30px;
        font-weight: 600;
        @extend .color-primary;
        sup {
          font-size: 18px;
        }
      }
    }
    .pricing-options {
      margin-bottom: 25px;
      li {
        padding: 10px 0;
      }
      li + li {
        border-top: 1px solid $border-color;
      }
      .custom-radio label {
        font-size: 16px;
        .pricing__opt {
          color: #333333;
          font-weight: 400;
        }
      }
    }
    .purchase-button {
      .btn {
        width: 100%;
        font-weight: 400;
      }
      .btn + .btn {
        margin-top: 30px;
      }
    }
  }

  &.card--pricing2 {
    padding: 0;
    .price {
      @extend .bg-primary;
      border-radius: 4px 4px 0 0;
      h1 {
        color: #fff !important;
      }
    }
    .pricing-options {
      padding: 0 30px;
      li p {
        padding-top: 18px;
        padding-left: 35px;
      }
    }
    .purchase-button {
      padding: 0 30px 30px;
    }
  }
}

.card-title {
  padding: 30px;
  border-bottom: 1px solid $border-color;
  margin-bottom: 0;
}

// card meta data
.card--metadata {
  ul.data > li {
    padding: 22px 0 22px;
    border-bottom: 1px solid $border-color;
    overflow: hidden;
    line-height: 30px;
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
    p {
      float: left;
      font-size: 16px;
      line-height: inherit;
      margin-bottom: 0;
      span {
        margin-right: 8px;
      }
    }
    > span {
      float: right;
      font-weight: 400;
      font-size: 18px;
      color: #333;
    }
  }
  .rating {
    text-align: center;
    padding-top: 28px;
  }
}

// product information card
.card--product-infos {
  padding: 0;
  ul.infos {
    padding: 19px 30px;
    li {
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      p {
        margin: 0;
        font-size: 15px;
        vertical-align: top;
        line-height: 36px;
      }
      p.data-label {
        &:after {
          content: ':';
          margin-left: 2px;
        }
        width: 40%;
        font-weight: 400;
        color: #333333;
      }
      p.info {
        width: 58%;
        color: #555555;
      }
    }
  }
}

/* Social Share Card */
.social-share-card {
  display: flex;
  align-items: center;
  padding: 30px;
  p {
    margin: 0 15px 0 0;
  }
  ul {
    li {
      display: inline-block;
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
      a {
        color: #cfd4e2;
        &:hover {
          @extend .color-primary;
        }
      }
    }
  }
}

/* SUPPORT PAGE SIDEBAR */
.support--sidebar {
  @extend .product--sidebar;
  .card--forum_categories {
    @extend .card--category;
  }
  .login_promot {
    @extend .bg-primary;
    color: #fff;
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 18px;
    border-radius: 4px;
    padding: 20px 0;
    display: block;
    text-align: center;
    span {
      margin-right: 10px;
    }
  }

  .card--top_discussion {
    .card-content {
      li {
        position: relative;
        padding-left: 23px;
        margin-bottom: 10px;
        &:before {
          position: absolute;
          content: '';
          height: 7px;
          width: 7px;
          -webkit-border-radius: 200px;
          -moz-border-radius: 200px;
          border-radius: 200px;
          background: #c5cad4;
          vertical-align: middle;
          left: 0;
          top: 9px;
        }
        a {
          @extend .color-primary;
          line-height: 26px;
          &:hover {
            @extend .color-primary;
          }
          span {
            display: block;
            color: $text-color2;
          }
        }
      }
    }
  }
}

/* FAQ SIDEBAR */
.faq--sidebar {
  @extend .support--sidebar;
  .faq--card {
    border: 0 none;
    @extend .card--top_discussion;
    .card-content {
      li:before {
        transform: translateY(0);
        top: 13px;
      }
      a {
        font-size: 16px;
      }
    }
    .btn--faq {
      text-align: center;
      margin-bottom: 38px;
    }
  }
}

// this is author card
.author-card {
  .card-title {
    padding: 30px;
  }
  &.sidebar-card {
    padding: 0;
  }
  .author-infos {
    padding: 30px;
    .author-top {
      display: flex;
      align-items: center;
    }
    .author_avatar {
      width: 100px;
      height: 100px;
      display: inline-block;
      border-radius: 4px;
      overflow: hidden;
      margin-right: 20px;
    }
    .author {
      h5 {
        line-height: 30px;
        color: $title-color;
      }
      p {
        line-height: 30px;
        padding-top: 6px;
        margin: 0;
        font-size: 15px;
        font-weight: 300;
      }

    }
    .social {
      margin-top: 25px;
      margin-bottom: 30px;
    }
    .author-btn {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .btn {
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &.author-card2 {
    text-align: center;
    .author-infos {
      padding: 50px 0 5px 0;
      .author_avatar {
        margin-right: 0;
        border-radius: 50%;
        margin-bottom: 15px;
      }
      .author p {
        line-height: 25px;
      }
      .social {
        display: block;
        border-top: 1px solid $border-color;
        margin: 35px 0;
        padding: 35px 30px 0 30px;
        text-align: left;
      }
      .author-btn {
        display: inline-block;
        margin-top: 20px;
      }
    }
  }
}

/* upload page sidebar */
.upload_sidebar {
  .card_content {
    padding: 10px 30px 26px;
    p {
      line-height: 30px;
      margin-bottom: 0;
    }
    ul {
      padding-left: 19px;
      padding-top: 20px;
      li {
        list-style-type: disc;
        line-height: 30px;
        font-size: 16px;
        color: $text-color2;
        & + li {
          margin-top: 13px;
        }
      }
    }
  }
  .card_info {
    h4 {
      color: $title-color;
      font-weight: 400;
      padding-bottom: 20px;
    }
  }
  .card_info + .card_info {
    margin-top: 30px;
  }
}

/* CARD BLOG SIDEBAR */
.sidebar--blog {
  @extend .support--sidebar;
  .card--blog_sidebar {
    ul.post-tab {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .card_content {
      padding: 0 25px;
    }
  }
  .card--search {
    .searc-wrap input {
      width: 100%;
    }
    .card_content {
      padding: 25px;
    }
  }

  .sidebar--post {
    .tab-pane{
      padding-bottom: 25px;
    }
    .card-title ul {
      overflow: hidden;
      li {
        a {
          font-size: 18px;
          color: $title-color;
          &.active {
            @extend .color-primary;
          }
        }
      }
    }
    .post-list {
      li {
        padding: 25px 0;
        display: flex;
        & + li {
          border-top: 1px solid $border-color;
        }
        &:last-child{
          padding-bottom: 0;
        }
      }
      .thumbnail_img {
        img {
          width: 100%;
        }
      }
      .title_area {
        padding-left: 15px;
        flex: 2;
        a {
          display: inline-block;
          font-weight: 400;
          margin-bottom: 6px;
          h6 {
            line-height: 24px;
            color: #333;
            font-weight: 400;
          }
        }
        .date_time {
          span {
            display: inline-block;
          }
          p {
            margin: 0;
            display: inline-block;
            color: #898f96;
            font-size: 15px;
          }
        }
      }
    }
  }

  .card--category {
    @extend .card--forum_categories;
  }

  .card--tags {
    .tags {
      padding: 25px 20px 11px;
      li {
        display: inline-block;
        margin: 0 5px 14px;

        a {
          color: $text-color2;
          background: transparentize($primary, 0.93);
          line-height: 30px;
          display: inline-block;
          padding: 0 15px;
          border-radius: 3px;
          &:hover {
            @extend .color-primary;
          }
        }
      }
    }
  }
}

.banner {
  position: relative;
  img {
    border-radius: 4px;
    width: 100%;
  }
  &:before {
    content: '';
    @include gradient;
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    opacity: 0.8;
  }
  .banner_content {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;
    h1 {
      color: #f9f9f9;
    }
    p {
      margin: 0;
      color: #f9f9f9;
    }
  }
}


.card--filter{
  .card-content{
    li{
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}