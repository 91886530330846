// this file contains the necessary style fr tha tab element
ul.nav-tabs {
  border: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  li {
    flex: auto;
    position: relative;
    border-bottom: 1px solid $border-color;
    a {
      color: $text-color2;
      font-size: 16px;
      text-transform: capitalize;
      display: block;
      margin-right: 0;
      line-height: 70px;
      border: none;
      transition: 0.3s;
      position: relative;
      text-align: center;
      font-weight: 300;
      &:before {
        content: "";
        position: absolute;
        height: 3px;
        width: 90%;
        @extend .bg-primary;
        opacity: 0;
        left: 5%;
        transition: $transition;
        visibility: hidden;
        bottom: 0;
      }
      &.active {
        @extend .color-primary;
        &:before {
          opacity: 1;
          visibility: visible;
        }
        border-bottom: 0;
      }
      a,
      a:focus,
      a:active,
      a:hover {
        border: 0;
        background: none;
        @extend .color-primary;
      }
      span {
        &.icon {
          display: inline-block;
          padding-right: 5px;
        }
      }
    }
  }
  &.nav--tabs2 {
    display: flex;
    justify-content: center;
    margin-bottom: -4px;
    li {
      flex: none;
      &::before {
        bottom: 0;
      }
      border: none;
      a {
        padding: 0 30px;
        font-weight: 400;
        &:before {
          width: 100%;
          left: 0;
          bottom: 3px;
        }
      }
    }
  }
}

.tab {
  .tab-content {
    margin-top: 30px;
  }
}

.tab2 {
  padding: 0 30px;
  .item-navigation {
    text-align: center;
    border-bottom: 1px solid $border-color;
  }
}

.tab3 {
  @extend .tab2;
  .item-navigation {
    text-align: left;
  }
  ul.nav-tabs li a {
    padding: 0 50px;
    span {
      padding-right: 7px;
    }
  }
}

.tab4 {
  padding: 30px;
  overflow: hidden;
  .item-navigation {
    float: left;
    width: 20%;
    border-bottom: 0;
  }
  .tab-content {
    width: 80%;
    float: left;
    margin-top: 0;
    .tab-pane {
      padding-bottom: 0;
    }
  }
  ul.nav-tabs {
    justify-content: flex-start;
    li {
      width: 100%;
      a {
        line-height: 50px;
        text-align: left;
        margin-right: 30px;
        padding-left: 0;
      }
      a:before {
        width: 3px;
        right: 0;
        left: auto;
        height: calc(100% - 10px);
      }
      a span {
        padding-right: 7px;
      }
    }
  }
}

.items-tab {
  padding: 0 30px;
}

.tab-content {
  margin-bottom: 0;
  .tab-pane {
    padding: 0 0 30px 0;
    .about_author.border-none {
      padding: 0 30px;
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
    }
  }
}