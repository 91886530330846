/*
  Name: Digipro - Digital Products Marketplace
  Author: AazzTech
  Version: 2.1.0
  Website: https://www.aazztech.com
*/

/**                              **
**       TABLE OF CONTENTS       **
**                               **
-----------------------------------*/
/*
  01.COMMON
    -- General
    -- Helper
    -- Mixins
    -- Reset

  02.COMPONENTS
    -- Accordion
    -- Alerts
    -- Breadcrumbs
    -- BTNs
    -- Cards
    -- Content Block
    -- Datepicker
    -- Dropdown
    -- Features
    -- Forms
    -- Menu
    -- Modal
    -- Pagination
    -- Pricing
    -- Product
    -- Progressbar
    -- Sidebar
    -- Social
    -- Tabs
    -- Tables
    -- Team
    -- Timeline

  03. MENU AREA
  04. HERO AREA
  05. PRODUCT SORTING
  06. FEATURED AREA
  07. LATEST PRODUCT
  08. SERVICES
  09. COUNTER UP
  10. HOW IT WORKS
  11. TESTIMONIAL
  12. CALL TO ACTION
  13. CLIENTS LOGO
  14. SUBSCRIBE
  15. FOOTER AREA
  16. FILTER AREA
  17. SINGLE PRODUCT DESC AREA
  18. AUTHOR AREA
  19. DASHBOARD
  20. DASHBOARD ADD CREDIT
  21. DASHBOARD STATEMENT
  22. DASHBOARD INVOICE
  23. DASHBOARD UPLOAD
  24. DASHBOARD EDIT PAGE
  25. DASHBOARD WITHDRAW
  26. NOTIFICATION
  27. MESSAGE AREA
  28. SHOPPING CART
  29. CHECKOUT
  30. HOW IT WORKS
  31. ABOUT US
  32. PARTNERS AREA
  33. TESTIMONIAL
  34. FAQ AREA
  35. AFFILIATE PAGE
  36. TERMS & CONDITION
  37. CONTACT AREA
  38. ORDER CONFIRMATION
  39. LOGIN
  40. SIGNUP
  41. SUPPORT
  42. SUPPORT DETAILS
  43. 404
  44. CAREER
  45. CAREER DETAILS
  46. APPLY FORM
  47. EVENT AREA
  48. EVENT DETAILS
  49. MAP
  50. BLOG
  51. BADGES
  52. RESPONSIVE CSS
***************************/