// range slider
.range-slider.ui-slider{
  height:6px;
  background: #efedf3;
  border-radius: 6px;
  .ui-slider-range{
    @extend .bg-primary;
  }
  .ui-slider-handle{
    height: 18px;
    width: 18px;
    @extend .bg-secondary;
    border-radius: 50px;
    top: -6px;
    z-index: 2;
    &:focus{
      outline: 0;
    }
    &:before{
      content: '';
      position: absolute;
      height: calc(100% - 8px);
      width: calc(100% - 8px);
      background: #fff;
      left: 4px;
      top: 4px;
      z-index: -1;
      border-radius: 50%;
    }
  }
}
.price-ranges{
  padding-top: 34px;
  text-align: left;
  span{
    line-height: 32px;
    display: inline-block;
    background: transparentize($primary, .9);
    width: 80px;
    @extend .color-primary;
    text-align: center;
  }
}
.search-update{
  margin-top:30px;
}