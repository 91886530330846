// generates theming classes based on theme color from $theme-colors map in bootstrap
@each  $key, $color in ($theme-colors) {
  .color-#{$key}{
    color: #{$color};
  }
  .bg-#{$key} {
    background: #{$color};
  }
  .iborder-#{$key}{
    color: #{$color};
  }
  .outline-#{$key}{
    border: 1px solid #{$color};
    color: #{$color}
  }
  .active-color-#{$key}{
    label input:checked + span {
      background: #{$color};
      border-color: #{$color};
    }
  }
  //generate bg with opacity and and text color
  .circle-#{$key}{
    color: #{$color};
    background: rgba($color, 0.1);
  }

  //generate border color
  .border-#{$key}{
    border: 1px solid #{$color};
  }
  .b-#{$key}{
    border-color: #{$color} !important;
  }
  .b-bottom-10-#{$key}{
    border-bottom: 10px solid #{$color};
  }
}

// generating classes based on other colors used in the theme
@each  $key, $color in ($other-colors) {
  .color-#{$key}{
    color: #{$color};
  }
  .bg-#{$key} {
    background: #{$color};
  }
}

//generate social colors
@each $key, $color in ($social-colors){
  .color-#{$key}{
    color: #{$color};
  }
}

//Generate border colors with four directions
@mixin border_generator($from, $to, $jump, $side){
  @for $i from $from through $to{
    @if $i%$jump == 0{
      @each  $key, $color in ($theme-colors){
        .b-#{$side}-#{$i}-#{$key}{
          border-#{$side}: #{$i}px solid #{$color};
        }
      }
    }
  }

}
@include border_generator(0, 5, 1, top);
@include border_generator(0, 5, 1, right);
@include border_generator(0, 5, 1, bottom);
@include border_generator(0, 5, 1, left);

//Generate border colors
@mixin border_generator2($from, $to, $jump){
  @for $i from $from through $to{
    @if $i%$jump == 0{
      @each  $key, $color in ($theme-colors){
        .b-#{$i}-#{$key}{
          border: #{$i}px solid #{$color} !important;
        }
      }
    }
  }
}
@include border_generator2(0, 5, 1);