// this file contains the style for the team component
/*****************************
	-- TEAM
*****************************/
/**/
.team_area {
  background: $bgcolor2;
  padding: 100px 0 70px 0;
}

.team-single {
  background: #fff;
  margin-bottom: 30px;
  border-radius: 3px;
  figure {
    text-align: center;
    padding: 30px 30px 25px;
    margin: 0;
    transition: $transition-base;
    &:hover {
      box-shadow: $box-shadow-lg2;
    }
    img {
      width: auto;
    }
    figcaption {
      margin-top: 25px;
      h5 {
        margin: 0 0 15px 0;
      }
      span.member-title {
        display: inline-block;
        font-size: 14px;
        font-weight: 300;
        @extend .bg-secondary;
        color: #fff;
        padding: 5px 15px;
        border-radius: 3px;
        line-height: initial;
        transition: $transition;
      }
      ul.team-social {
        margin-top: 25px;
        li {
          display: inline-block;
          margin-right: 20px;
          &:last-child {
            margin-right: 0;
          }
          a {
            color: $text-color2;
            &:hover {
              @extend .color-primary;
              stroke: $primary;
            }
          }
        }
      }
    }
    &:hover {
      figcaption {
        span.member-title {
          @extend .bg-primary;
        }
      }
    }
  }
}