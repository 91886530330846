@import "../mixins/functions";
@import "../../../vendor_assets/css/bootstrap/config.bs";

@import "testimonial";
@import "forum";
@import "footer";
@import "breadcrumb";
@import "content-block";
@import "menu";
@import "product";
@import "sidebar";
@import "tab";
@import "team";
@import "timeline";