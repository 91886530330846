@mixin linear-gradient($from, $to) {
  background: $from;
  background: -moz-linear-gradient(to right, $from, $to);
  background: -webkit-linear-gradient(to right, $from, $to);
  background: -o-linear-gradient(to right, $from, $to);
  background: linear-gradient(to right, $from, $to);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$from}, endColorstr=#{$to})
}

// gradient
//$gradient: linear-gradient(to left, $secondary 0%,  $primary 100%);
@mixin gradient{
  background-image: linear-gradient(to left, $secondary 0%,  $primary 100%);
  /* Fallback (could use .jpg/.png alternatively) */
  background-color: $secondary;

  /* SVG fallback for IE 9 (could be data URI, or could use filter) */
  background: $primary;

  /* Safari 4, Chrome 1-9, iOS 3.2-4.3, Android 2.1-3.0 */
  background-image:
          -webkit-gradient(linear, left top, right top, from($secondary), to($primary));

  /* Safari 5.1, iOS 5.0-6.1, Chrome 10-25, Android 4.0-4.3 */
  background-image:
          -webkit-linear-gradient(left, $secondary, $primary);

  /* Firefox 3.6 - 15 */
  background-image:
          -moz-linear-gradient(left, $secondary, $primary);

  /* Opera 11.1 - 12 */
  background-image:
          -o-linear-gradient(left, $secondary, $primary);

  /* Opera 15+, Chrome 25+, IE 10+, Firefox 16+, Safari 6.1+, iOS 7+, Android 4.4+ */
  background-image:
          linear-gradient(to right, $secondary, $primary);
}